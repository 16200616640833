import { useRouter } from 'next/router'
import useSWR from 'swr'

import * as T from 'types'

import { fetch } from '../fetchers'
import getErrorMessage from './utils/getErrorMessage'

export const useListingPhotos = (listingId?: string | null, mapToCloudinary = false) => {
  const router = useRouter()
  const key = listingId ? `/listings/${listingId || router.query.id}/photos` : null

  const response = useSWR<T.IPhoto[] | T.IServerError>(key, fetch)

  const error = getErrorMessage(response, 'photos', true)
  const photos = error ? undefined : (response.data as T.IPhoto[] | undefined)

  if (mapToCloudinary) {
    const photosFromCloudinary = photos?.map(photo => {
      const urlParts = photo.url.split('/')
      const filename = urlParts.pop()
      const folderName = urlParts.pop()
      const cloudinaryUrl = `https://res.cloudinary.com/homelister/image/upload/w_1200,q_auto:best,dpr_auto,f_auto/amazon/${folderName}/${filename}`
      return {
        ...photo,
        url: cloudinaryUrl,
      }
    })

    return { ...response, error, photos: photosFromCloudinary }
  }

  return { ...response, error, photos }
}
